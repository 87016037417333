export class DOMOperator {
    #element;

    constructor(htmlString) {
        const template = document.createElement('template');
        template.innerHTML = htmlString;
        this.#element = template.content.firstElementChild;
    }

    getElement() {
        return this.#element;
    }

    attachTo(parent, position = 'afterbegin') {
        parent.insertAdjacentElement(position, this.#element);
    }

    addWidgetTo(grid, size) {
        const xywh = size?.split(',');
        grid.addWidget(this.#element, {
            x: Number(xywh[0]),
            y: Number(xywh[1]),
            w: Number(xywh[2]?? '3'),
            h: Number(xywh[3]?? '3')
        });
    }

    removeFrom(parent) {
        if (parent !== this.#element.parentElement) {
            throw new Error('Parent mismatch!');
        }
        parent.removeChild(this.#element);
    }

    attach(component, position = 'afterbegin') {
        component.attachTo(this.#element, position);
    }
}


