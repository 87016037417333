
/**
 TargetPing 클래스는 지정된 HTML 요소에 시각적 강조 효과를 적용하는 역할을 합니다.
 웹 페이지의 특정 요소에 사용자의 주의를 끌거나,
 인터랙션의 중요성을 강조하는 등의 시각적 피드백을 효과적으로 제공할 수 있습니다.

 ### 사용 가능한 옵션
     - 애니메이션 반복 횟수:repeat (default: 2)
     - 애니메이션 반복 갭 시간:repeatBuffer (default: 400)
     - 애니메이션 지속 시간:playTime (default: 1000)
     - 애니메이션 시작 전 지연 시간:buffer (default: 100)
     - 애니메이션 타입:animType (default: 'spread')
     - 테두리 색상:lineColor (default: '#6449fc')
     - 요소의 위치 고정 여부:isStatic (default: true)

 ### 애니메이션 타입
     - 'focus'는 요소를 강조하여 더욱 돋보이게 하며,
     - 'spread'는 요소에서부터 확산되는 효과를 제공.

 ### 사용 예
 ``` javascript
 const ping1 = new Mutil.TargetPing($('#myElement'), { animType: 'spread' });
 const ping2 = new Mutil.TargetPing($('#myElement'), { playTime: 1.5, lineColor: '#ff0000' });
 ```
 */
export class TargetPing {
    constructor($target, options = {}) {
        if (!Mutil.isFunc("TARGET_PING")) return;
        this.options = options;
        const play = async () => {
            this.init($target);
            for (let i = 0; i < this.repeat; i++) {
                this.settingHighlight();
                if (this.repeat > (i + 1))
                    await Mutil.delay(this.repeatBuffer)
            }
        }

        const buffer = this.options?.buffer ?? 100;
        Mutil.delay(buffer).then(play);
    }

    animationCssInit(type) {
        switch (type) {
            case "focus":
                return {transform: 'scale(1.5)', opacity: 0};
            case "spread":
            default:
                return {transform: 'scale(1)', opacity: 1};
        }
    }
    animationCssAfter(type) {
        switch (type) {
            case "focus":
                return {transform: 'scale(1)', opacity: 1};
            case "spread":
            default:
                return {transform: 'scale(1.1)', opacity: 0};

        }
    }

    init($target) {
        this.$target = $($target);
        this.repeat = this.options?.repeat ?? 2; // 단위 (갯수)
        this.repeatBuffer = this.options?.repeatBuffer ?? 400; // 단위 (ms)
        this.playTime = this.options?.playTime ?? 1000; // 단위 (ms)
        this.animType = this.options?.animType ?? 'spread'; // 'focus' 또는 'spread'
        this.lineColor = this.options?.lineColor ?? '#6449fc';
        this.isStatic = this.options?.isStatic ?? true;
    }

    /**
     * 하이라이트 초기화. (애니메이션 시작 전 CSS 세팅을 합니다.)
     */
    settingHighlight() {
        let $ping = null;
        if ((this.$target?.length ?? 0) === 0) {
            console.debug("[TARGET_PING] TARGET NOT FOUND");
            return;
        }

        if (!this.$target.css('position') || this.$target.css('position') === 'static') {
            this.$target.css('position', 'relative');
        }

        const basicCss = {
            position: 'absolute',
            border: `4px solid ${this.lineColor}`,
            boxSizing: 'border-box',
            transition: `all ${(this.playTime * 0.001) * 0.5}s ease-in-out`,
            zIndex: 9999999999
        };

        if (this.isStatic) {
            const position = this.$target.offset();
            const width = this.$target.outerWidth();
            const height = this.$target.outerHeight();
            if (!position || !width || !height) return;
            const positionCss = {top: position?.top, left: position?.left, width: width, height: height};
            $ping = $('<div></div>').css({
                ...basicCss,
                ...positionCss,
                ...this.animationCssInit(this.animType)
            });
            $('body').append($ping);
        } else {
            const positionCss = {top: 0, left: 0, width: '100%', height: '100%',};
            $ping = $('<div></div>').css({
                ...basicCss,
                ...positionCss,
                ...this.animationCssInit(this.animType)
            });
            this.$target.append($ping);
        }

        this.startAnimation($ping);
    }

    /**
     * ### startAnimation 메서드
     *     1. 비동기적으로 애니메이션을 실행하여,
     *     2. 설정된 지연 시간 후에 애니메이션을 시작하고,
     *     3. 지정된 지속 시간 동안 애니메이션을 유지한 다음,
     *     4. 요소를 제거합니다.
     * 이는 애니메이션의 흐름을 제어하고, 강조 효과가 자연스럽게 사라지도록 합니다.
     * @returns {Promise<void>}
     */
    async startAnimation($ping) {
        $ping.css({visibility: 'hidden'})
        await Mutil.delay(100);
        $ping.css({visibility: ''})
        $ping.css(this.animationCssAfter(this.animType));
        await Mutil.delay(this.playTime * 0.5);
        $ping.css({opacity: 0}); // end anim;
        await Mutil.delay(this.playTime * 0.5);
        $ping.remove();
    }
}