import {exist, n2v} from "./Often.js";

const TIME_FORMAT = {
    'YYYY-MM-DD HH:mm:ss': 'YYYY-MM-DD HH:mm:ss',
    'YYYY-MM-DD HH:mm': 'YYYY-MM-DD HH:mm',
    'YYYY-MM-DD (ddd)': 'YYYY-MM-DD (ddd)',
    'YYYY-MM-DD (ddd), HH:mm': 'YYYY-MM-DD (ddd), HH:mm',
    'YYYY-MM-DD': 'YYYY-MM-DD',
    'YYYY-MM': 'YYYY-MM',
    'YYYYMM': 'YYYYMM',
    'MM/DD': 'MM/DD',
    'MM/DD, HH:mm': 'MM/DD, HH:mm',
    'YYYY': 'YYYY',
    'YYYYMMDDHHmmss': 'YYYYMMDDHHmmss',
    'YYYYMMDDHHmm': 'YYYYMMDDHHmm',
    'YYYYMMDD': 'YYYYMMDD',
    'DD/MM/YYYY': 'DD/MM/YYYY',
    'DD-MM-YYYY HH:mm': 'DD-MM-YYYY HH:mm',
}

const makeDttm = (v = "") => (n2v(v) + "00000000000000").substring(0, 14);
const formatDate = (date, type) => dayjs(date).format(type);
const formatDttm = (v, formatStr = TIME_FORMAT["YYYY-MM-DD HH:mm"]) => {
    const returnDttm = (exist(v) ? dayjs(makeDttm(v)) : dayjs()).format(formatStr);
    return "Invalid Date" === returnDttm ? "" : returnDttm;
};

const convertDttm = (date = "") => formatDttm(date, TIME_FORMAT['YYYY-MM-DD']);
const getNowDttm = (type) => formatDttm("", type || TIME_FORMAT.YYYYMMDDHHmmss);
const getNowDay = (type) => formatDttm("", type || TIME_FORMAT.YYYYMMDD);
const getNowMonth = (isBar) => formatDate(new Date(), TIME_FORMAT[`YYYY${isBar ? '-' : ''}MM`]);

const getYear = (dttm = "") => (dttm.length > 0 ? dayjs(dttm) : dayjs()).year();
const getWeekOfYear = (dttm = "") => (dttm.length > 0 ? dayjs(dttm) : dayjs()).isoWeek();
const getDateByWeekOfYear = (y, w, dddd) => dayjs(String(y, '')).week(+w).day(+dddd).format("MM/DD");
const getMonFriDate = (y, w) => getDateByWeekOfYear(y, w, 1) + " ~ " + getDateByWeekOfYear(y, w, 5);

const getDateBeforeOneDay = (date) => dayjs(date).subtract(1, 'day').format();
const getDateBeforeOneWeek = (date) => dayjs(date).subtract(1, 'week').format();
const getDateBeforeOneMonth = (date) => dayjs(date).subtract(1, 'month').format();
const getDateBeforeThreeMonth = (date) => dayjs(date).subtract(3, 'month').format();
const getDateBeforeSixMonth = (date) => dayjs(date).subtract(6, 'month').format();
const getDateBeforeTenYear = (date) => dayjs(date).subtract(10, 'year').format();

const getDateAfterOneDay = (date, format = '') => dayjs(date).add(1, 'day').format(format);
const getDateAfterThreeMonth = (date) => dayjs(date).add(3, 'month').add(-1, 'day').format();
const getDateAfterTenYear = (date, format = '') => dayjs(date).add(10, 'year').add(-1, 'day').format(format);

const DEFAULT_PROFILE_URL = '/flow-renewal/assets/images/profile-default.svg';

function getProfilesIcon(v) {
    const {MANAGER_RECORD = [], PROFILE_URL = ''} = v;
    const COUNT = 0, NAME = '', ID = '';
    if (MANAGER_RECORD?.length > 0) {
        return {
            PROFILE_URL: n2v(MANAGER_RECORD[0].PROFILE_URL || MANAGER_RECORD[0].WORKER_PRFL_PHTG, DEFAULT_PROFILE_URL),
            COUNT: MANAGER_RECORD.length - 1,
            NAME: MANAGER_RECORD[0].NAME || MANAGER_RECORD[0].WORKER_NM,
            ID: MANAGER_RECORD[0].ID || MANAGER_RECORD[0].WORKER_ID,
        }
    }
    if (exist(PROFILE_URL)) {
        return {PROFILE_URL, COUNT, NAME, ID}
    }
    return {PROFILE_URL: DEFAULT_PROFILE_URL, COUNT, NAME, ID}
}

export {
    makeDttm,
    getProfilesIcon,
    convertDttm,
    getYear,
    getWeekOfYear,
    formatDate,
    formatDttm,
    getNowDttm,
    getNowDay,
    getNowMonth,
    TIME_FORMAT,
    getMonFriDate,
    getDateBeforeOneDay,
    getDateBeforeOneWeek,
    getDateBeforeOneMonth,
    getDateBeforeThreeMonth,
    getDateBeforeSixMonth,
    getDateBeforeTenYear,
    getDateAfterOneDay,
    getDateAfterThreeMonth,
    getDateAfterTenYear,
}
