import {toast} from "./Toast";
import {confirm} from "./Confirm";
import {getLocal} from "./LocalStorage";
import {parse} from "./Json";

const requireIIFE = (nameArr, isAlert = true) => {
    const isNameArrString = typeof nameArr === 'string';
    if (isNameArrString) nameArr = [nameArr];
    const undefinedModules = [];
    const modules = nameArr.map(name => {
        const module = window[name];
        if (!module) undefinedModules.push(name);
        return module;
    });
    if (isAlert && undefinedModules.length > 0) {
        confirm({
            text: {contents: `Not Found [${undefinedModules.join(',')}] IIFE!`},
            display: {cancelBtn: false}
        });
    }
    return isNameArrString ? modules[0] : modules;
}
const noop = (_, __, ___) => '';
const isFunc = (name) => !!parse(getLocal("ONLY_FUNC_LIST"))?.[name];
const _s4 = () => ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
const guid = () => _s4() + _s4() + '-' + _s4() + '-' + _s4() + '-' + _s4() + '-' + _s4() + _s4() + _s4();
const isTarget = (e, sizzleName) => (sizzleName instanceof Array ? sizzleName : [sizzleName]).filter(v => $(e.target).closest(v).length > 0).length > 0
const exist = v => v instanceof Array ? v.length > 0 : v instanceof Object ? Object.keys(v).length > 0 : "" !== String(n2v(v), '').trim();
const exists = exist;
const empty = v => exist(v) === false;
const numberWithCommas = x => (+x).toLocaleString('en');
const isAct = (key) => location.pathname.includes(key);
//0부터 length 까지 숫자 배열 생성
const range = (length) => Array.from({length}, (_, i) => i);
const logPipe = (any, ...data) => {
    clog(typeof any, any, ...data);
    return any;
}
const calculateMs = (cb, cycle = 1) => {
    const before = performance.now();
    range(cycle).forEach(() => cb())
    return Math.ceil(performance.now() - before) + 'ms';
}

function isServerOn(serverMode = "DEV_TEST") {
    const lHost = location.host;
    const isReal = () => lHost.includes("flow.team") && !isRealTest() && !isDevTest() && !isSpecialSubDom();
    const isSpecialSubDom = () => lHost.includes("seco.flow.team") || lHost.includes("zoomok.flow.team");
    const isRealTest = () => lHost.includes("develop.flow.team") || lHost.includes("staging.flow.team") ||
        lHost.includes("release.flow.team") || lHost.includes("hotfix.flow.team")
    const isDevTest = () => lHost.includes("localhost") || lHost.includes("flowdev.info") ||
        lHost.includes("flowteam.info") || lHost.includes("flowtest.info") || lHost.includes("127.0.0.1")
    const isEnter = () => window.ServerChecker?.isEnter && !lHost.includes('dev') && !isDevTest() && !isSpecialSubDom();
    return {
        "REAL": isReal(),
        "ALL_TEST": (isRealTest() || isDevTest()),
        "REAL_TEST": isRealTest(),
        "DEV_TEST": isDevTest(),
        "ENTER": isEnter(),
    } [serverMode]
}

function getServerMode() {
    const lHost = location.host;

    const isRealTest = () =>
        lHost.includes("develop.flow.team")
        || lHost.includes("staging.flow.team")
        || lHost.includes("release.flow.team")
        || lHost.includes("hotfix.flow.team")
        || lHost.includes("staging.morningmate.com")
        || lHost.includes("release.morningmate.com")
        || lHost.includes("hotfix.morningmate.com");

    const isDevTest = () =>
        lHost.includes("localhost")
        || lHost.includes("flowdev.info")
        || lHost.includes("flowteam.info")
        || lHost.includes("flowtest.info")
        || lHost.includes("develop.morningmate.com")
        || lHost.includes("morningmate.info");

    const isSpecialSubDom = () =>
        lHost.includes("seco.flow.team")
        || lHost.includes("zoomok.flow.team");

    const isReal = () =>
        (lHost.includes("flow.team") || lHost.includes("morningmate.com"))
        && !isRealTest()
        && !isDevTest()
        && !isSpecialSubDom();

    if (isReal()) return "REAL";
    else if (isRealTest()) return "REAL_TEST";
    else if (isDevTest()) return "DEV_TEST";
    else if (isRealTest() || isDevTest()) return "ALL_TEST";
}

function n2v(value, str) {
    if (value == null || value === "" || typeof (value) === undefined || value === "null" || value === "undefined" || value === undefined) {
        if (typeof str === "string") return str.trim();
        if (typeof str === "number") return str;
        return "";
    }
    if (typeof value === "string" && value !== "ranne ") return value.trim();
    return value;
}

function attr($targetObj) {
    const arr = attrs($targetObj);
    if (arr.length > 0) return arr[0];
    return {}
}

function attrs($targetObj) {
    const objAttrArray = [];
    for (let len = 0, objLen = $targetObj.length; len < objLen; len++) {
        let att;
        const atts = $targetObj[len].attributes;
        const objJson = {};
        for (let i = 0, n = atts.length; i < n; i++) {
            att = atts[i];
            const key = att.nodeName.toUpperCase().replace(/[-]/ig, "_").replace("DATA_", "");
            const isAdd = !['ID', 'CLASS', 'CONTENTEDITABLE', 'STYLE', 'DATA'].includes(key);
            isAdd && (objJson[key] = att.nodeValue);
        }
        objAttrArray.push(objJson);
    }
    return objAttrArray;
}

function toggleAction($btn, $area, {
    make = noop, show = noop, hide = noop,
} = {}) {
    if ($btn.hasClass("active")) {
        $area.css('display', 'none');
        hide($btn, $area);
    } else {
        if ($area.length === 0) {
            make($btn, $area);
        } else {
            $area.css('display', 'flex');
            show($btn, $area);
        }
    }
    $btn.toggleClass("active");
}

const showNHide = (isShow, $el) => isShow ? $el.show() : $el.hide();
const showNRemove = (isShow, $el) => isShow ? $el.show() : $el.remove();

function isBrowser(key) {
    const lowUA = navigator.userAgent.toLowerCase();
    const browser = {
        ie6: lowUA.includes('msie 6'),
        ie7: lowUA.includes('msie 7'),
        ie8: lowUA.includes('msie 8'),
        ie9: lowUA.includes('msie 9'),
        ie10: lowUA.includes('msie 10'),
        ie11: lowUA.includes('msie 11'),
        ie: lowUA.includes('netscape') || lowUA.includes('trident') || lowUA.includes('msie'),
        edge: lowUA.includes('edge'),
        edg: lowUA.includes('edg'),
        opera: !!window.opera,
        safari: lowUA.includes('safari') && !lowUA.includes('chrome'),
        safari3: lowUA.includes('applewebkir/5'),
        mac: lowUA.includes('mac'),
        chrome: lowUA.includes('chrome'),
        firefox: lowUA.includes('firefox'),
        whale: lowUA.includes("whale"),
    }
    return key ? browser[key] : browser;
}

function isClientOs() {
    const ua = navigator.userAgent.toLowerCase();
    return {
        isWin: ua.includes("windows"),
        isMac: ua.includes("macintosh"),
        isWin7: ua.includes("windows nt 6.1"),
        isWin8: ua.includes("windows nt 6.2"),
        isWin10: ua.includes("windows nt 10.0"),
    };
}
function isTablet() {
    const ua = navigator.userAgent;
    return !!ua.match(/iPad|Android/);
}

function isMobile() {
    const ua = navigator.userAgent;
    const mobileFlag = /Mobile|iP(hone|od)|Windows (CE|Phone)|Minimo|Opera M(obi|ini)|BlackBerry|Nokia/;
    return ua.match(mobileFlag) && !ua.match(/iPad/);
}

function getCookie(name) {
    let cookieKey = name + "=", result = "", cookieArr = document.cookie.split(";");
    for (let i = 0, l = cookieArr.length; i < l; i++) {
        if (cookieArr[i][0] === " ") (cookieArr[i] = cookieArr[i].substring(1));
        if (cookieArr[i].indexOf(cookieKey) === 0) {
            return unescape(cookieArr[i].slice(cookieKey.length, cookieArr[i].length));
        }
    }
    return result;
}

function setCookie(name, value, expireDays, expireTime) {
    const todayDate = new Date();
    let strExpOpt = "", strExpDt = "";
    if (n2v(expireDays) !== "" || n2v(expireTime) !== "") {
        expireDays ? todayDate.setDate(todayDate.getDate() + expireDays) : todayDate.setTime(todayDate.getTime() + expireTime);
        strExpOpt = "expires=";
        strExpDt = todayDate.toGMTString() + ";";
    }
    const secure = (location.protocol === "http:") ? "" : "; secure";
    document.cookie = `${name}=${escape(value)}; path=/; ${strExpOpt + strExpDt + secure}`;
}

function deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

function getUUID(userId, deviceId) {
    if (exist(userId) && exist(deviceId)) {
        return userId + "_" + deviceId + '_xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    } else {
        toast("error", "필수값 체크");
    }
}

function isJson(text) {
    try {
        JSON.parse(text);
    } catch (e) {
        return false;
    }
    return true;
}

function copyClipboard(txt) {
    if (!navigator.clipboard) {
        toast("error", "클립보드 복사를 지원하지 않습니다.");
        return;
    }
    navigator.clipboard.writeText(txt).then(() => toast("success", "클립 보드에 저장했습니다."));
}

const pipe = (...funcs) => (v) => funcs.reduce((res, func) => func(res), v);
const uniq = (arr) => [...new Set(arr)];

//let obj1 = {name: 'kim', age: {x: 32, y: 33}}
//let obj2 = {age: {y: 33, x: 32}, name: 'kim'}
//deepCompare(obj1, obj2)
function deepCompare(arg1, arg2) {
    if (Object.prototype.toString.call(arg1) !== Object.prototype.toString.call(arg2)) return false;
    if (Object.prototype.toString.call(arg1) === '[object Object]' || Object.prototype.toString.call(arg1) === '[object Array]') {
        if (Object.keys(arg1).length !== Object.keys(arg2).length) return false;
        return (Object.keys(arg1).every((key) => deepCompare(arg1[key], arg2[key])));
    }
    return (arg1 === arg2);
}

function delay(ms = 0) {
    return new Promise((res) => setTimeout(res, ms));
}

function chunk(data = [], size = 1) {
    const arr = [];
    for (let i = 0; i < data.length; i += size) {
        arr.push(data.slice(i, i + size));
    }
    return arr;
}

function clog(...data) {
    if (isServerOn("REAL") || isServerOn("ENTER")) return;
    console.log('[개발서버 전용로그]', ...data);
}

function elog(...data) {
    console.error(...data);
}

function mlog(...data) {
    //개발과 운영에서는 마드라스체크만 허용
    if ((isServerOn("REAL") && !isMadras()) || isServerOn("ENTER") ) return;
    console.log('[마드라스체크 전용로그]', ...data);
}

function isMadras() {
    return window._USE_INTT_ID === 'UTLZ_1608261809693';
}
function isEncodedUrl(url = "") {
    if(empty(url)) {
        return false;
    }
    try {
        const decodedUrl = decodeURIComponent(url);
        return decodedUrl !== url;
    } catch (error) {
        return false;
    }
}

function isGlobal() {
    return location.host.includes("morningmate");
}
function isGuest() {
    return (window._USE_INTT_ID?.startsWith("GMAIL") || window._USE_INTT_ID?.startsWith("KAKAO") ||
        window._USE_INTT_ID?.startsWith("FLOW") || window._USE_INTT_ID?.startsWith("APPLE")) && window._ENTER_YN !== 'Y'
}
function getAdminPage() {
    const obfuscatedStr = "\\x66\\x6c\\x6f\\x77\\x5f\\x61\\x64\\x6d\\x69\\x6e\\x2e\\x61\\x63\\x74";
    return obfuscatedStr.split('\\x').filter(Boolean).map(hex => String.fromCharCode(parseInt(hex, 16))).join('');
}
function getAdmin() {
    const obfuscatedStr = "\\x66\\x6c\\x6f\\x77\\x5f\\x61\\x64\\x6d\\x69\\x6e";
    return obfuscatedStr.split('\\x').filter(Boolean).map(hex => String.fromCharCode(parseInt(hex, 16))).join('');
}
export {
    copyClipboard,
    isFunc,
    isAct,
    requireIIFE,
    noop,
    isTarget,
    n2v,
    isClientOs,
    isTablet,
    isMobile,
    exist,
    exists,
    empty,
    logPipe,
    calculateMs,
    numberWithCommas,
    attr,
    attrs,
    range,
    toggleAction,
    showNHide,
    showNRemove,
    isBrowser,
    getCookie,
    setCookie,
    deleteCookie,
    guid,
    isJson,
    getUUID,
    pipe,
    uniq,
    deepCompare,
    isServerOn,
    getServerMode,
    delay,
    chunk,
    clog,
    elog,
    mlog,
    isEncodedUrl,
    isGlobal,
    isGuest,
    getAdmin,
    getAdminPage,
}
