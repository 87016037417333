import {n2v, exist, guid, deepCompare, delay, isFunc} from './Often';
import {confirm} from './Confirm';

export const AJAX_OPTION = {
    ALLOW_EXECUTE: 'ALLOW_EXECUTE', // 중복 실행 허용 && 중복 콜백 허용
    PREVENT_EXECUTE: 'PREVENT_EXECUTE', // 중복 실행 방지( = 첫번째 실행이 종료될때까지 방지)
    PREVENT_CALLBACK: 'PREVENT_CALLBACK',  // 중복 콜백 무시 ( = 마지막 콜백이 실행 ) => Race Condition
};

const instance = {};

export class Ajax {

    /**
     *
     * @param spaceName - 별도 스코프가 필요하다면 네이밍을 정해준다. / 없다면 packetOption를 네이밍으로 가져간다.
     * @param packetOption - 중복실행, 중복콜백 방지
     * @returns {*}
     */
    constructor({spaceName, packetOption} = {}) {
        spaceName = spaceName || packetOption || AJAX_OPTION.ALLOW_EXECUTE
        if (instance[spaceName]) {
            instance[spaceName].count++;
            return instance[spaceName];
        }
        this.count = 0;
        this.returnJson = {};
        this.packetOption = packetOption || AJAX_OPTION.ALLOW_EXECUTE;
        this.id = `ajax-${guid()}`;
        instance[spaceName] = this;
    }

    setApiData(apiKey, k, v) {
        if (apiKey === undefined) return;
        if (!this.returnJson[apiKey]) {
            this.returnJson[apiKey] = {};
        }
        this.returnJson[apiKey][k] = v;
    }

    getData(apiKey) {
        if (apiKey === undefined || this.returnJson[apiKey] === undefined) return {};
        return this.returnJson[apiKey];
    }

    async executeApi(apiKey, inputJson = {}, skipConfirm = false, isMaster = false) {

        let result;

        const nowMilliSecond = Date.now();
        const {isExecute} = this.getData(apiKey);

        if (this.packetOption === AJAX_OPTION.PREVENT_EXECUTE && isExecute) {
            return {};
        } // 중복 실행 방지

        this.setApiData(apiKey, "request", inputJson);
        this.setApiData(apiKey, "isExecute", true);
        this.setApiData(apiKey, "finalResMilliSecond", nowMilliSecond);

        const url = `/${apiKey}.jct${makeQuery(inputJson)}`;
        const json = make_JSON_(inputJson);

        const _request = await Mutil.lib.getRequest();
        try {
            result = await _request.post(url).type('form').send(json)
            result.body = Time.tzApi(result.body);
        } catch (e) {
            console.error(e);
            if (e.status === 401) location.href = e.getResponseHeader("REDIRECT_URL");
        }

        isFunc('API_DELAY_500') && await delay(500);
        if (isMaster && Often.isGlobal()) await delay(800);

        this.setApiData(apiKey, "isExecute", false);
        this.setApiData(apiKey, "response", result.body);

        const {COMMON_HEAD = {}} = result.body;

        if (COMMON_HEAD.ERROR) {
            const isBadRequest = COMMON_HEAD.CODE === "400";
            var isNoSession = !exist(window._RGSN_DTTM) && isBadRequest;

            const TeamsHelper = Mutil.requireIIFE('TeamsHelper', false)
            if (TeamsHelper?.isTeams() && isNoSession) {
                location.replace('/teams/');
                return;
            } else if (isNoSession) {
                location.reload();
                return;
            }

            if (COMMON_HEAD.CODE === "403") {
                const {ERROR_INTERNATIONAL_CODE, ERROR_MSG = null, MESSAGE = null} = COMMON_HEAD.DATA || {};
                const defaultMessage = ERROR_MSG ?? MESSAGE ?? ''
                const errorCode = `exception:${ERROR_INTERNATIONAL_CODE}`
                const errorMessage = i18next.exists(errorCode) ? i18next.t(errorCode) : defaultMessage
                const message = ERROR_MSG.includes('Error Code -> exception') ? ERROR_INTERNATIONAL_CODE : errorMessage;
                Mutil.toast('error', message);
                throw {error: true, message};
            }

            if (COMMON_HEAD.CODE === "404" || COMMON_HEAD.CODE === "409") {
                throw COMMON_HEAD;
            }

            if (COMMON_HEAD.CODE === "E1") {
                Often.toast("error", COMMON_HEAD.MESSAGE)
                return COMMON_HEAD.CODE;
            }

            const contentsPrefix = isBadRequest ? i18next.t(common.notAllowedData) : i18next.t(common.unknownServerError);
            const contents = contentsPrefix + `\n` + i18next.t(common.pleaseRefresh);

            if (COMMON_HEAD.CODE === 'REQ001') { // USER_ID, RGSN_DTTM 필수값입니다! (SecureFilter)
                confirm({
                    text: {contents, more: COMMON_HEAD.MESSAGE},
                    display: {cancelBtn: false},
                    callback: {
                        submitFn: () => Often.logoutDirect(),
                        cancelFn: () => Often.logoutDirect()
                    }
                })
                clearClientDataNSocket();
                return;
            }

            if (COMMON_HEAD.CODE === "S0002") {
                confirm({
                    text: {contents, more: JSON.stringify(COMMON_HEAD)},
                    display: {cancelBtn: false},
                    callback: {
                        submitFn: () => Often.logoutDirect(),
                        cancelFn: () => Often.logoutDirect()
                    }
                })
                clearClientDataNSocket();
                return;
            }
            if (COMMON_HEAD.CODE === "9849") {
                Mutil.confirm({
                    text: {contents: i18next.t(common.security.alartSetLoginSecurityDesc),},
                    display: {cancelBtn: false},
                    callback: {
                        submitFn: () => {
                            Loading.drawLoadingPop();
                            Often.logoutDirect();
                        },
                        cancelFn: () => {
                            Loading.drawLoadingPop();
                            Often.logoutDirect();
                        },
                    }
                });
                return;
            }

            if (COMMON_HEAD.CODE === '9209') {
                const {DATA = {}} = COMMON_HEAD;
                Mutil.toast('error', DATA?.ERROR_MSG);
                return {};
            }


            if (COMMON_HEAD.CODE === "400") {
                const {ERROR_INTERNATIONAL_CODE, ERROR_MSG = null, MESSAGE = null} = COMMON_HEAD.DATA || {};
                if(ERROR_INTERNATIONAL_CODE){
                    Often.toast("error", Often.null2Void(i18next.t('exception:' + ERROR_INTERNATIONAL_CODE)));
                } else {
                    Often.toast("error", ERROR_MSG);
                }
                throw COMMON_HEAD;
                return;
            }


            if (!skipConfirm) {
                confirm({
                    text: {contents, more: JSON.stringify(COMMON_HEAD)},
                    display: {cancelBtn: false}
                })
            }
            throw COMMON_HEAD;
        }

        if (this.packetOption === AJAX_OPTION.PREVENT_CALLBACK &&
            isSameRequest(this.getData(apiKey), inputJson, nowMilliSecond)) {
            throw "isSameRequest";
        }

        return result.body;
    }
}

function clearClientDataNSocket() {
    window._USER_ID = '';
    window._RGSN_DTTM = '';

    const MainSocket = Mutil.requireIIFE('MainSocket', false);
    const Often = Mutil.requireIIFE('Often', false);
    const LocalUtil = Mutil.requireIIFE('LocalUtil', false);
    const StatusSocket = requireModule('StatusSocket', {on: ['main', 'miniMain', 'messenger']});

    SocketControl.getSocket().disconnect();
    MainSocket?.disconnectSocket();
    StatusSocket()?.disconnectStatusSocket();
    LocalUtil.setLocal("ONLY_DEVICE_NM", "");
    LocalUtil.setLocal("ONLY_DEVICE_ID", "");
    ['flowLogin', 'miniflowLogin', 'googleLoginYn', 'LAST_SUB_PATH', 'FLOW_DUID'].forEach((v) => {
        Often?.setCookie(v, '', 30 * 12);
    });
    Often?.setCookie('autoLoginCheckYN', 'N', 30 * 12); //빈값으로 지정하면 디폴트('Y') 처리되기 때문에 N으로 지정해야함.
}

/**
 * Note. 중복콜백 체크시 해당 keyArray 는 예외하고 진행한다.
 * Ex1. 전체업무 상태 필터를 빠른 속도로 OnOff 할때 INPUT 에서 FIL_STTS, FIL_PRIORITY 빼고 비교해야함
 * Ex2. 담당자 검색을 빠른 속도로 OnOff 할때 SRCH_WORD 빼고 비교해야함
 */
function isSameRequest({request, finalResMilliSecond}, inputJson, nowMilliSecond) {
    const lastReqInputJson = {...request};
    const currentReqInputJson = {...inputJson};
    [
        "PG_PER_CNT",
        "FILTER_RECORD",
        "FIL_STTS",
        "FIL_PRIORITY",
        "SRCH_WORD",
        "SEARCH_RECORD"
    ].forEach(key => {
        lastReqInputJson[key] = "";
        currentReqInputJson[key] = "";
    })
    return finalResMilliSecond !== nowMilliSecond && //시간이 다른데
        deepCompare(lastReqInputJson, currentReqInputJson); //인풋이 같으면
}

/**
 * @description 실시간 갱신용 로직.
 * 실시간 갱신을 위해 업데이트가 되었음을 알려줘야하는 API 가 호출되는 경우엔 바로 소켓을 보내준다.
 * 링크참고: https://docs.google.com/spreadsheets/d/1ExSL2EvEZ52ahiF-ZuBnjB7AcLcNLgWlgCyCu23OOoQ/edit#gid=1287712533
 */
function isSocketTarget(apiKey) {
    return [
        'COLABO2_COMMT_U101',
        'COLABO2_COMMT_C101',
        'COLABO2_COMMT_D101',
        'FLOW_SUBTASK_C001',
        'FLOW_SUBTASK_D001',
        'COLABO2_TASK_U001',
        'FLOW_TASK_WORKER_U002',
        'FLOW_VOTE_C001',
        'FLOW_SCHD_ATD_U001',
        'ACT_MEMO',
    ].indexOf(apiKey) > -1
}

function make_JSON_(inputJson) {
    inputJson.RGSN_DTTM = window._RGSN_DTTM;
    return {
        _JSON_: encodeURIComponent(JSON.stringify({
            USER_ID: n2v(window._USER_ID),
            RGSN_DTTM: n2v(window._RGSN_DTTM),
            ...Time.tzrApi(inputJson)
        }))
    }
}

function makeQuery({MODE, GUBUN, TYPE, PG_NO, CRUD_TYPE}) {
    let queryString = "";
    if (exist(MODE)) {
        queryString = "&mode=" + MODE;
    } else if (exist(GUBUN)) {
        queryString = "&mode=" + GUBUN;
    } else if (exist(TYPE)) {
        queryString = "&type=" + TYPE;
    } else if (exist(CRUD_TYPE)) {
        queryString = "&type=" + CRUD_TYPE;
    }
    if (exist(PG_NO) && Number(PG_NO) > 1) {
        queryString += "&page=" + PG_NO
    }
    if ("" !== queryString) {
        queryString = "?" + queryString.slice(1);
    }
    return queryString;
}
