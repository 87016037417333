export const ResourceIdCrypto = () => {
    const HASH = 'x0EYlZ5tbAvg43yQsCTwu91zpFDKLqhHSRUBao8PNVf2OiWcXIJrn7Mkd6ejmG'
    const HASH_LENGTH = HASH.length

    // encode resource id
    const encodeResourceId = (resourceId) => {
        if (typeof resourceId !== 'number') {
            return
        }

        let id = resourceId
        let encoded = ''
        while (id > 0) {
            const char = HASH.charAt(id % HASH_LENGTH)
            encoded += char
            id = Math.floor(id / HASH_LENGTH)
        }

        return encoded.split('').reverse().join('')
    }

    // decode resource id
    const decodeResourceId = (encodedResourceId) => {
        if (typeof encodedResourceId !== 'string') {
            return
        }

        let decoded = 0
        for (let i = 0; i < encodedResourceId.length; i++) {
            decoded = decoded * HASH_LENGTH + HASH.indexOf(encodedResourceId.charAt(i))
        }

        return decoded
    }

    return {
        encodeResourceId,
        decodeResourceId,
    }
}