import {n2v} from "./Often";

const removeLocal = (k) => localStorage.removeItem(convertKeyByTabId(k));
const setLocal = (k, v) => {
    checkStorageSize()
    localStorage.setItem(convertKeyByTabId(k), v)
}
const getLocal = (k) => localStorage.getItem(convertKeyByTabId(k));
const setLocalJson = (k, json) => {
    checkStorageSize()
    localStorage.setItem(convertKeyByTabId(k), JSON.stringify(json))
}
const getLocalJson = (k) => n2v(getLocalValue(k), {});
const setLocalValue = (k, key, value) => {
    try {
        let tempJson = {...JSON.parse(getLocal(k))};
        tempJson[key] = value;
        setLocal(k, JSON.stringify(tempJson));
    } catch (e) {
    }
};
const getLocalValue = (k, key) => {
    try {
        if (key) return JSON.parse(getLocal(k))[key];
        return JSON.parse(getLocal(k));
    } catch (e) {
        return n2v(getLocal(k));
    }
};

const checkStorageSize = () => {
    // step01: 사이냅뷰어 데이터
    if (isLocalStorageQuotaExceeded()) {
        purgeDataByKeyPrefix('synap_')
    }

    // step02: 담당자 추천 캐싱 데이터
    if (isLocalStorageQuotaExceeded()) {
        const keys = purgeDataByKeyPrefix('N_recommend_user_')
        saveLocalStorageLog({
            keys
        })
    }

    // step03: 무서운거 (원인모름)
    if (isLocalStorageQuotaExceeded()) {
        const data = getMostMassDataKey()
        saveLocalStorageLog(data)
    }
}

function isLocalStorageQuotaExceeded(rate = 0.9) {
    const quotaLimit = 5 * 1024 * 1024 // 5MiB
    const quota = Math.floor(quotaLimit * rate)
    const quotaUsed = new Blob(Object.values(localStorage)).size
    return quota <= quotaUsed
}

function purgeDataByKeyPrefix(prefix) {
    // find keys
    const keys = Object.keys(localStorage).filter(key => key.startsWith(prefix))

    // remove all data
    for (const key of keys) {
        localStorage.removeItem(key)
    }

    // return keys
    return keys
}

function getMostMassDataKey() {
    let maxKey = ''
    let max = 0

    for (const key of Object.keys(localStorage)) {
        const data = localStorage.getItem(key)
        const size = new Blob([data]).size
        if (size > max) {
            maxKey = key
            max = size
        }
    }

    return {
        key: maxKey,
        size: max,
    }
}

function saveLocalStorageLog(data) {
    Ajax.executeApi("ACT_FEATURE_LOG_C001", {
        FEATURE_ID: 'LOCAL_STORAGE_QUOTA_EXCEEDED',
        DETAIL: JSON.stringify(data),
    })
}

//같은 계정으로 두 가지 탭을 켜는 경우에 발생할 수 있는 이슈를 해결하기 위함
function convertKeyByTabId(k) {
    k = k || '';
    if (k.includes("TAB_")) return "N_" + k + "_" + n2v(window._TAB_ID); //같은 계정이라도 탭 별로 정보를 달리 보관
    return "N_" + k; //같은 계정이라면 하나만 보관
}

export {removeLocal, setLocal, getLocal, setLocalJson, getLocalJson, setLocalValue, getLocalValue, checkStorageSize}