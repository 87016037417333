import {exists, n2v, isFunc} from "./Often";

export const convertBase64ToFile = (dataurl, fileName) => {
    let File = window.File;
    try {
        new File([], '')
    } catch (e) {
        File = class File extends Blob {
            constructor(chunks, filename, opts = {}) {
                super(chunks, opts)
                this.lastModifiedDate = new Date()
                this.lastModified = +this.lastModifiedDate
                this.name = filename
            }
        }
    }

    const arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1], name = fileName + "." + mime.split("/")[1],
        bstr = atob(arr[1]), u8arr = new Uint8Array(n);
    let n = bstr.length;

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], name, {type: mime});
}

export function getFileInfo(fullName) {
    if (!fullName) return {NAME: "", EXT: ""}
    const startIndex = fullName.lastIndexOf(".");
    const lastIndex = fullName.length;
    const name = startIndex === -1 ? fullName : fullName.substring(0, startIndex);
    let extension;
    try {
        extension = startIndex === -1 ? "" : fullName.substring(startIndex + 1, lastIndex).toLowerCase();
    } catch (e) {
        extension = "";
    }
    return {name, extension}
}

export function isImageExtension(ext) {
    return "image" === getStandardExtension(ext);
}

export function getStandardExtension(ext) {
    if (exists(ext) === false) return "ETC";
    ext = ext.toLowerCase();
    const standardExtension = {
        DEFAULT: ['default'],
        CLOUD: ['googleDrive', 'dropbox', 'onedrive'],
        PDF: ['pdf'],
        WORD: ['doc', 'docx'],
        EXCEL: ['xlsx', 'xlsm', 'xlsb', 'xltx', 'xltxm', 'xls', 'xlt', 'xls', 'xlam'],
        HWP: ['hwp'],
        PPT: ['ppt', 'pptx'],
        IMAGE: ['png', 'jpeg', 'jpg', 'bmp', 'rle', 'gif', 'pddv', 'tif', 'tiff', 'raw', 'al', 'eps', 'svg', 'svgz', 'iff', 'fpxv', 'frm', 'pcx', 'pct', 'pic', 'pxr', 'sct', 'tga', 'vda', 'icb', 'vst'],
        MEDIA: ['avi', 'mpg', 'mpeg', 'mpe', 'wmv', 'asf', 'asx', 'flv', 'rm', 'mov', 'dat', 'mpeg1', 'mpeg2', 'mpeg4', 'mp3', 'mp4', 'ogg', 'wma', 'wav', 'au', 'mid'],
        HTML: ['html'],
        AUTOCAD: ['dwg', 'dwgc', 'dws', 'dxf', 'dwt', 'plt', 'lsp'],
        ZIP: ['alz', 'ace', 'arc', 'arj', 'b64', 'bh', 'bhx', 'bin', 'bz2', 'cab', 'ear', 'enc', 'gz', 'ha', 'hqx', 'ice', 'img', 'jar', 'lha', 'lzh', 'mim', 'pak', 'rar', 'sit', 'tar', 'tgz', 'uue', 'war', 'xxe', 'z', 'zip', 'zoo', '001'], //Todo. 필터없음
        TXT: ['txt'],
        PSD: ['psd'],
        AI: ['ai'],
    }
    let targetExtension = Object.keys(standardExtension).filter(key => standardExtension[key].includes(ext));
    targetExtension = targetExtension.length > 0 ? targetExtension[0] : 'ETC';
    return targetExtension.toLowerCase();
}

export function json2formData(json) {
    const formData = new FormData();
    Object.keys(json).forEach(key => formData.append(key, json[key]));
    return formData;
}

export function getValidImageSrc(path, defaultImg) {
    const defaultImageByService = location.href.indexOf('morningmate') > -1  ? '/flow-renewal/assets/images/notice/notice_default_mm.png'
        : "/flow-renewal/assets/images/notice/notice_default.jpg";
    const defaultImage = defaultImg || defaultImageByService;
    return new Promise((resolve) => {
        if (!path) return resolve(defaultImage);
        const img = new Image();
        img.setAttribute('crossOrigin', 'anonymous');
        img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            resolve(canvas.toDataURL("image/png"));
        }
        img.onerror = () => resolve(defaultImage);
        img.src = path;
    })
}

/**
 * @param {string} fileByteSize
 * @returns {string} - 소수점 둘째짜리까지 표기됨
 */
export function getFileSize(fileByteSize) {
    if (fileByteSize && isNaN(+fileByteSize)) return fileByteSize;
    if (!fileByteSize || +fileByteSize === 0) return "-";
    var isOver1MB = fileByteSize > 1024 * 1024;
    var isOver1KB = fileByteSize > 1024;
    if (isOver1MB) return (Math.round((fileByteSize / (1024 * 1024) * 100)) / 100.0) + " MB";
    if (isOver1KB) return (Math.round((fileByteSize / (1024) * 100)) / 100.0) + " KB";
    return fileByteSize + " B";
}

export function isImageType(fileData) {
    //NOTE. 썸네일 존재하고, 이미지확장자이며, 20MB 이하일때만 이미지타입!
    return (n2v(fileData.THUM_IMG_PATH, "") !== "" &&
            (!isFunc("UPLOAD_IMAGE_LIKE_FILE")
                || n2v(fileData.DRM_YN, "") !== "Y"))&&
        isImageExtension(getFileInfo(fileData.FILE_NM).extension) &&
        isImageSize(fileData.FILE_SIZE)
}

function isImageSize(fileSize) {
    if (Number(fileSize) < 20971520) return true; //20MB
    return false;
}

/**
 * 이미지 파일 url을 받아 파일 오브젝트로 변환합니다.
 * @param url 이미지 파일
 * @returns {Promise<window.File>} 변환된 파일 object
 */
export async function convertImageUrlToFileObj(url) {
    const response = await fetch(url);
    const data = await response.blob();
    const ext = url.split(".").pop();
    const orgFilename = url.split("/").pop();
    const filename = orgFilename.replace(".", `_${_USER_ID}_${Math.floor(+ new Date() / 1000)}.`);
    const metadata = { type: `image/${ext}` };
    return new File([data], filename, metadata);
}