import {isBrowser} from "./Often";

function isKey({key, keyCode}, code) {

    if (code instanceof Array) {
        return code.reduce((acc, cur) => acc || isKey({key, keyCode}, cur), false);
    }

    const isIe = isBrowser("ie");
    const isCode = (_number, _ieKey, _key) => {
        if (Number(keyCode) === _number) return (Number(keyCode) === _number);
        return key === (isIe ? _ieKey : _key);
    }
    const keycodeJson = {
        BACK: isCode(8, "Backspace", "Backspace"),
        TAB: isCode(9, "Tab", "Tab"),
        ENTER: isCode(13, "Enter", "Enter"),
        SHIFT: isCode(16, "Shift", "Shift"),
        CTRL: isCode(17, "Control", "Control"),
        META: isCode(91, "Meta", "Meta"),
        ALT: isCode(18, "Alt", "Alt"),
        ESC: isCode(27, "Esc", "Escape"),
        SPACE_BAR: isCode(32, "Spacebar", " "),
        LEFT: isCode(37, "Left", "ArrowLeft"),
        UP: isCode(38, "Up", "ArrowUp"),
        RIGHT: isCode(39, "Right", "ArrowRight"),
        DOWN: isCode(40, "Down", "ArrowDown"),
        DELETE: isCode(46, "Del", "Delete"),
        F: isCode(70, "F", "F"),
        PLUS: isCode(107, "Add", "+"),
        MINUS: isCode(109, "Subtract", "-"),
        RELOAD_WINDOW: isCode(116, "F5", "F5"),
        RELOAD_MAC: isCode(82, "R", "R"),
        KOREAN_INPUTTING: isCode(229, "Process", "Process"),
        ARROW: isCode(37, "Left", "ArrowLeft") ||
            isCode(38, "Up", "ArrowUp") ||
            isCode(39, "Right", "ArrowRight") ||
            isCode(40, "Down", "ArrowDown"),
        CAPS_LOCK: isCode(20, "CapsLock", "CapsLock"),
        NUM_LOCK: isCode(144, "NumLock", "NumLock"),
        PRINT_SCREEN: isCode(124, "PrintScreen", "PrintScreen"),
        SCROLL_ROCK: isCode(145, "Scroll", "ScrollLock"),
        PAUSE: isCode(19, "Pause", "Pause"),
        INSERT: isCode(45, "Insert", "Insert"),
        END: isCode(35, "End", "End"),
        PAGE_UP: isCode(33, "PageUp", "PageUp"),
        PAGE_DOWN: isCode(34, "PageDown", "PageDown"),
    }

    if (code) return keycodeJson[code];
    return keycodeJson;
}
function isAvailableKeyCode(e) {
    return (!((e.keyCode >= 65 && e.keyCode <= 90) ||
        (e.keyCode >= 48 && e.keyCode <= 57) ||
        (e.keyCode >= 96 && e.keyCode <= 105) ||
        specialCharactersKeyCode(e.keyCode) ||
        (isKey(e, "BACK") || isKey(e, "DELETE") ||
            isKey(e, "SPACE_BAR") || isKey(e, "ENTER") || isKey(e, "KOREAN_INPUTTING")) ||
        (Often.isTablet() && e.keyCode === 0)));
}

function specialCharactersKeyCode(keyCode) {
    switch (keyCode) {
        case 106: case 107: case 109: case 110: case 111:
        case 186: case 187: case 188: case 189: case 190:
        case 191: case 192: case 219: case 220: case 221:
        case 222:
            return true;
        default:
            return false;
    }
}

function isUpperCaseKeyCode(e) {
    var key = e.key;
    if (key.length === 1 && /[A-Z]/.test(key)) {
        return true;
    }
    return false;
}

export {isKey, isUpperCaseKeyCode, isAvailableKeyCode}
