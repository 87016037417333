import {noop} from "./Often";
import {decrypt, encrypt} from "./Json";

const views = {};
const callbacks = {};

const ViewController = (function () {

    function addRouter(_code, $elementArray, {isChangeable = noop, prevShow = noop, nextShow = noop}) {
        views[_code] = $elementArray;
        callbacks[_code] = {isChangeable, prevShow, nextShow};
    }

    async function changeView(_code, _json, _options = {}) {
        await callbacks[_code].prevShow(_json);
        for (let code in views) {
            views[code].forEach(v => v.hide());
        }
        views[_code].forEach(v => v.show());
        callbacks[_code].nextShow(_json);

        if (_options.back) return;

        const id = encrypt(_json);
        const idQuery = !_json || Object.keys(_json).length === 0 ? '' : `=${id}`;
        history.pushState({page: _code}, _code, `${location.origin}/okr.act?${_code}${idQuery}`);
    }

    function getCurrentCode() {
        return location.search
            .slice(1)
            .split('&')
            .map(p => p.split('='))
            .reduce((obj, [key, value]) => ({...obj, code: key, json: decrypt(value)}), {});
    }

    return {addRouter, changeView, getCurrentCode}
})()

export {ViewController}