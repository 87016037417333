import {isBrowser} from "./Often.js";
import {toast} from './Toast.js';
import {confirm as CustomConfirm} from './Confirm.js';

let normal, success, error, warning, info, question, confirm;

normal = (message) => {
    toast('info', message);
};
success = (message) => {
    toast('success', message);
};
error = (message) => {
    toast('error', message);
};
warning = (message) => {
    toast('warning', message);
};
info = (message) => {
    toast('info', message);
};
question = (message) => {
    toast('info', message);
};
confirm = ({title, content = "", confirm, cancel, confirmCallback, cancelCallback}) => {
    CustomConfirm({
        text: {
            contents: title + '\n' + content,
            submit: confirm,
            cancel: cancel || '취소'
        },
        callback: {
            submitFn: confirmCallback,
            cancelFn: cancelCallback,
        }
    })
};
export {normal, success, error, warning, info, question, confirm}

//
// if (isBrowser('ie')) {
//
// } else {
//     import('../lib/swal/sweetalert2.all.min.js').then((swal) => {
//
//         const _Swal = window.Sweetalert2 || swal;
//
//         const confirmSwal = _Swal.mixin({
//             showCancelButton: true,
//         });
//
//         const Toast = _Swal.mixin({
//             toast: true,
//             position: 'top',
//             showConfirmButton: false,
//             timer: 3000,
//             timerProgressBar: true,
//             didOpen: (toast) => {
//                 toast.addEventListener('mouseenter', _Swal.stopTimer)
//                 toast.addEventListener('mouseleave', _Swal.resumeTimer)
//             }
//         });
//
//         /**
//          * ### Toast를 생성합니다.<br>
//          * - 아이콘 타입 : normal<br>
//          * - ex, Toast.normal("내용");
//          * @param text toast 내용
//          */
//         normal = text => {
//             if (!text || text === '') return;
//             Toast.fire({title: text});
//         }
//
//         /**
//          * ### success 타입 Toast를 생성합니다.<br>
//          * - 아이콘 타입 : success<br>
//          * - ex, Toast.success("내용");
//          * @param text toast 내용
//          */
//         success = text => {
//             if (!text || text === '') return;
//             Toast.fire({title: text, icon: 'success'});
//         }
//
//         /**
//          * ### error 타입 Toast를 생성합니다.<br>
//          * - 아이콘 타입 : error<br>
//          * - ex, Toast.error("내용");
//          * @param text toast 내용
//          */
//         error = text => {
//             if (!text || text === '') return;
//             Toast.fire({title: text, icon: 'error'});
//         }
//
//         /**
//          * ### warning 타입 Toast를 생성합니다.<br>
//          * - 아이콘 타입 : warning<br>
//          * - ex, Toast.warning("내용");
//          * @param text toast 내용
//          */
//         warning = text => {
//             if (!text || text === '') return;
//             Toast.fire({title: text, icon: 'warning'});
//         }
//
//         /**
//          * ### info 타입 Toast를 생성합니다.<br>
//          * - 아이콘 타입 : info<br>
//          * - ex, Toast.info("내용");
//          * @param text toast 내용
//          */
//         info = text => {
//             if (!text || text === '') return;
//             Toast.fire({title: text, icon: 'info'});
//         }
//
//         /**
//          * ### question 타입 Toast를 생성합니다.<br>
//          * - 아이콘 타입 : question<br>
//          * - ex, Toast.question("내용");
//          * @param text toast 내용
//          */
//         question = text => {
//             if (!text || text === '') return;
//             Toast.fire({title: text, icon: 'question'});
//         }
//
//         /**
//          * 확인창을 생성합니다.
//          * @param props
//          * - title: 제목<br>
//          * - content: 내용<br>
//          * - confirm: 확인버튼명<br>
//          * - cancel: 취소버튼명<br>
//          * - confirmCallback: 확인 콜백<br>
//          * - deniedCallback: 거절 콜백<br>
//          * - cancelCallback: 취소 콜백<br>
//          */
//         confirm = (props) => {
//             toast('')
//             confirmSwal.fire({
//                 title: props.title ?? '',
//                 html: props.content ?? '',
//                 icon: props.icon ?? null,
//                 confirmButtonText: props.confirm ?? '확인',
//                 cancelButtonText: props.cancel ?? '취소'
//             }).then((result) => {
//                 if (result.isConfirmed && props.confirmCallback) {
//                     props.confirmCallback();
//                 }
//                 if (result.isDenied && props.deniedCallback) {
//                     props.deniedCallback();
//                 }
//                 if (result.isDismissed && props.cancelCallback) {
//                     props.cancelCallback();
//                 }
//             })
//         }
//     });
// }


