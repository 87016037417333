import {isTarget, noop} from "./Often";
import {toast} from "./Toast";

const $prompt = () => $("#prompt");

export function prompt(options = {}) {

    const firstInput = options.renders[0].key;
    const {submit = noop, final = noop} = options.callbacks || {};

    const closeConfirmPop = () => $prompt().remove();

    setPromptStyle();
    closeConfirmPop();

    $('body').prepend(getConfirmHtml("prompt", options));

    const _$prompt = $prompt();

    _$prompt.on("mousedown mouseup click", (e) => {
        e.stopPropagation();
    }).on("click", async (e) => {
        switch (true) {
            case isTarget(e, [".close", ".no"]):
            case !isTarget(e, ".layer") :
                closeConfirmPop();
                break;
            case isTarget(e, [".yes"]) :
                const _data = {};
                let unValid = {is: false};
                $(e.currentTarget).find('.inputc:visible').each((_, v) => {
                    if (unValid.is) return;
                    const $v = $(v);
                    const isRequired = !!$v.attr('required');
                    const val = $.trim($v.val());
                    if (isRequired && val.length === 0) {
                        const name = $v.parents('tr').find('th').text().replace(' *', '');
                        unValid = {$el: $v, message: `Check '${name}'!!!`, is: true};
                        return;
                    }
                    _data[$v.filter(':visible').attr('class').replace('inputc ', '')] = val;
                })
                if (unValid.is) {
                    toast("error", unValid.message);
                    unValid.$el.focus();
                    return;
                }

                submit(_data);
                closeConfirmPop();
                break;
            default:
                break;
        }
    }).on("change", (e) => {
        switch (true) {
            case isTarget(e, ["[type=radio]"]):
                const sibling = $(e.target).parents('tr').attr('id');
                const target = $(e.target).val();
                $(`.${sibling}_radio_render`).hide();
                $(`.${target}_radio_render`).show();
                break;
            default:
                break;
        }
    });

    final(_$prompt);

    _$prompt.find(`input.${firstInput}`).focus();
}

function getConfirmHtml(id, options) {
    const {title, renders, submitText = '확인'} = options;

    return `
        <div id="${id}" class="prompt">
            <div class="back"></div>
            <div class="layer">
                <div class="top">
                    <h1 class="head">
                        <span class="title">${title}</span>
                        <input class="close" type="button">
                    </h1>
                </div>
                <div class="body">
                    <div class="cont">
                        <table>
                            <colgroup>
                                <col class="colc"><col>
                            </colgroup>
                            <tbody>
                                ${renders.map(item => convertItem(item)).join('')}   
                            </tbody>
                        </table>                                  
                    </div>
                    <div class="btn">
                        <button class="no">취소</button>
                        <button class="yes">${submitText}</button>
                    </div>
                </div>
            </div>
        </div>
    `
}

function convertItem(item) {
    const {id, cls, type, key, label, max, placeholder, required, options, display} = item;

    if (type === 'line') {
        return `<tr><th><br/><div class="hr"></div><br/></th></tr>`
    }
    if (type === 'subtitle') {
        return `<tr class="${cls}" ><th class="sub-title"><br/>${label}</th></tr>`
    }
    if (type === 'radio') {
        return `
            <tr id="${key}" class="${cls}">
                <th class="thc">${label} ${required ? '*' : ''}</th>
                <td class="tdc radio">
                    ${options.map(({key: option_key, label: option_label}, idx) => `
                        <div id="${option_key}Option">
                          <input 
                            type="radio" id="${option_key}" value="${option_key}" name="${key}" 
                            ${idx === 0 ? 'checked="checked"' : ''} ${required ? 'required' : ''}">
                          <label for="${option_key}">${option_label}</label>
                        </div>
                    `).join('')}
                </td>
            </tr>
            ${options.map(({key: option_key, renders}) => renders?.map(r => convertItem({
            ...r, cls: `${key}_radio_render ${option_key}_radio_render`, key: `${option_key}_${r.key || key}`
        }))).flat().join('')} 
        `
    }
    if (type === 'date') {
        return `
            <tr id="${id}" class="${cls}" ${display ? `style="display:${display}"` : ''}>
                <th class="thc">${label} ${required ? '*' : ''}</th>
                <td class="tdc date">
                    <input type="date" name="START_${key}" class="inputc START_${key}" ${required ? 'required' : ''}>
                    <span> ~ </span> 
                    <input type="date" name="END_${key}" class="inputc END_${key}" ${required ? 'required' : ''}>
                </td>
            </tr>`
    }
    if (type === 'datetime-local') {
        return `
            <tr id="${id}" class="${cls}" ${display ? `style="display:${display}"` : ''}>
                <th class="thc">${label} ${required ? '*' : ''}</th>
                <td class="tdc date">
                    <input type="datetime-local" name="START_${key}" class="inputc START_${key}" ${required ? 'required' : ''}>
                </td>
            </tr>`
    }
    return `
            <tr id="${id}" class="${cls}">
                <th class="thc">${label} ${required ? '*' : ''}</th>
                <td class="tdc">
                    <input 
                        class="inputc ${key}" 
                        type="${type}" 
                        ${max ? `maxlength=${max}` : ``}
                        placeholder="${placeholder}" 
                        ${required ? 'required' : ''}>
                </td>
            </tr>`
}

function setPromptStyle() {
    if ($("#promptStyle").length > 0) return;
    $("head").append(`
        <style id="promptStyle">
        .prompt {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100vw;
            height: 100vh;
            position: absolute;
        }
        .prompt .back {
            position: fixed; 
            width: 100%; 
            height: 100%; 
            overflow: hidden; 
            background: rgb(0, 0, 0); 
            opacity: 0.3; 
            z-index: 100;
        }
        .prompt .layer {
            width: 500px; 
            height: auto; 
            z-index: 100; 
            background-color: rgb(255, 255, 255);
        }
        .prompt .top {
            padding: 25px 30px 0;
        }
        .prompt .top .head {
            padding-bottom: 14px; 
            font-size: 19px; 
            color: rgb(17, 17, 17); 
            font-weight: 700; 
            border-bottom: 1px solid rgb(230, 230, 230);
            display: flex;
            justify-content: space-between;
        }
        .prompt .top .head .title {
            font-size: 19px; 
            color: rgb(17, 17, 17); 
            font-weight: 700;
        }
        .prompt .top .head .close {
           cursor: pointer; 
           width: 30px; 
           height: 30px; 
           border: 0; 
           background: url("/design2/flow_admin_2019/img/btn_popclose.gif") center center no-repeat;
        }
        .prompt .body {
           padding: 0 30px; 
           margin-bottom: 30px;
        }
        .prompt .body .cont {
               margin-top: 10px;
        }
        .prompt .body .btn {
           margin-top: 15px; 
           text-align: center; 
           width: 100%;
        }
        .prompt .sub-title {
            font-size: 14px;
            font-weight: bold;
            width: 200px;
            display: block;
            padding: 10px 0;
            text-align: left;
            color: rgb(48, 124, 255);
        }
        .prompt .tdc.radio {
            display: flex;
            gap: 10px;
        }
        .prompt .tdc.date {
            display: flex;
            align-items: center;
            width: 98.5%;
            margin-top: 8px;
        }
        .prompt .tdc.date span{
            margin: 0 10px;
        }
        .prompt .hr {
            border-bottom: 1px solid rgb(230, 230, 230);
            margin: 10px 0;
            width: 434px;
        }
        .prompt button.upload {
            padding: 4px;
            border-radius: 8px;
            margin-left: 4px;
        }
        </style>`);
}
