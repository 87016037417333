function closeLayerById($eTarget, idButtons) {
    for (let id in idButtons) {
        if (idButtons.hasOwnProperty(id)) {
            closeLayer("id", $eTarget, id, idButtons[id]);
        }
    }
}

function closeLayerByClass($eTarget, classButtons) {
    for (let className in classButtons) {
        if (classButtons.hasOwnProperty(className)) {
            closeLayer("class", $eTarget, className, classButtons[className]);
        }
    }
}

function closeLayer(mode, $eTarget, code, callback) {
    const isClass = mode === "class";
    const layer = isClass ? `.js-${code}-layer` : `#${code}Layer`,
        button = isClass ? `.js-${code}-button` : `#${code}Button`;
    const $layer = $eTarget.closest(layer);
    const $button = $eTarget.closest(button);
    const isClose = ($layer.length === 0 && $button.length === 0) && $(layer).is(":visible");
    if (!isClose) return;
    if (callback && typeof callback === "function") {
        callback($eTarget);
    } else {
        $(button).removeClass("active");
        $(layer).css("display", "none");
    }
}

export {closeLayerById, closeLayerByClass}