export class DraftRemarkData {
    // static draftRemarkDataMap = new Map()
    static instance = null;
    draftRemarkDataMap = new Map();

    constructor() {
        // this.draftRemarkDataMap = new Map()
    }

    static getInstance() {
        if (!this.instance) {
            this.instance = new DraftRemarkData()
        }
        return this.instance
    }

    setDraftDataMap({key, value}) {
        if (!key || !value) {
            Mutil.mlog(`::::::::::::::::::::::::::::::::::::::::::::`)
            Mutil.mlog(`setDraftDataMap :::: Key or Value is empty`)
            Mutil.mlog(`key ::: ${key}`)
            Mutil.mlog(`value :::`, value)
            Mutil.mlog(`::::::::::::::::::::::::::::::::::::::::::::`)
        }
        this.draftRemarkDataMap.set(key, value)
    }

    getDraftDataMap() {
        return this.draftRemarkDataMap
    }

    getDraftDataByKey(key) {
        return this.draftRemarkDataMap.get(key)
    }

    deleteDraftDataByKey(key) {
        if (key) {
            this.draftRemarkDataMap.delete(key);
            return true;
        } else {
            throw new Error(`deleteDraftDataByKey :::: Key is empty`);
        }
    }
}