export class DebounceNThrottle {

    timer = null;

    constructor(fn, duration) {
        if (typeof fn !== 'function') {
            throw new Error('Check!');
        }
        this.fn = fn;
        this.duration = duration || 500;
    }

    debounce = (argument) => {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            this.fn(argument);
            this.timer = null;
        }, this.duration);
    }

    debounceAfterStart = (argument) => {
        clearTimeout(this.timer);
        if (this.timer) {
            Mutil.clog('Skip On debounceAfterStart');
        } else {
            this.fn(argument);
        }
        this.timer = setTimeout(() => {
            this.timer = null;
        }, this.duration);
    }

    throttle = (argument) => {
        if (this.timer) return;
        this.timer = setTimeout(() => {
            this.fn(argument);
            this.timer = null;
        }, this.duration);
    }

    throttleAfterStart = (argument) => {
        if (this.timer) return;
        this.fn(argument);
        this.timer = setTimeout(() => {
            this.timer = null;
        }, this.duration);
    }
}