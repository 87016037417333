const jqueryUi = (() => {
    function sortable({$ul, handleSizzleName}) {
        if (!$ul || !handleSizzleName) return;
        if (typeof $().sortable === "undefined") return;
        if ($ul instanceof Array) $ul.forEach($v => $v.sortable({handle: handleSizzleName, invertSwap: true}));
        else $ul.sortable({handle: handleSizzleName, invertSwap: true});
    }

    function tooltip({$ul}) {
        if (!$ul) return;
        if (typeof $().tooltip === "undefined") return;
        if ($ul instanceof Array) $ul.forEach($v => $v.tooltip({track: true}));
        else $ul.tooltip({track: true});
    }

    return {sortable, tooltip}
})();

export default jqueryUi;