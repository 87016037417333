export class RecordControl {

    constructor(records) {
        this.records = records;
    }

    modifyRecordBy$El(props) {
        const {type, record, $el} = props;
        const isDraftRemark = !!record.TEMP_REMARK_REC
        if (type === 'ADD') this.setRecords([record])
        else if (type === 'ADD_FIRST') this.setRecords([record], true)
        else if (type === 'UPDATE') this.updateRecordByIndex({record, index: this.getIdx($el), isDraftRemark});
        else if (type === 'DELETE') this.deleteRecord(record);
    }

    updateRecordByIndex({record, index, isDraftRemark = false}) {
        this.records = this.records.map((originRecord, i) => {
            if (isDraftRemark) {
                const matchRecord = record.TEMP_REMARK_REC?.find(item => item.idx === i)
                return {...originRecord, ...{TEMP_REMARK_REC: matchRecord}};
            } else if (i === index) {
                const update = {...originRecord, ...record};
                if(record.TEMP_REMARK_REC === undefined) { //안들어오더라도 draft 유지
                    update.TEMP_REMARK_REC = originRecord.TEMP_REMARK_REC;
                }
                return update;
            } else {
                return originRecord;
            }
        });
    }

    deleteRecord(record) {
        for (let i = 0; i < this.records.length; i++) {
            if (this.records[i].COLABO_COMMT_SRNO === record.COLABO_COMMT_SRNO) {
                this.records.splice(i, 1)
                break;
            }
        }
    }

    setRecords(records, isReverse) {
        if (isReverse) {
            records.reverse()
            this.records = [...records, ...this.records]
        } else {
            this.records = [...this.records, ...records];
        }
    }

    getRecordBy$El($el) {
        return this.records[this.getIdx($el)] || {};
    }

    getRecords() {
        return this.records;
    }

    getIdx($el) {
        return $el.parent().children().index($el);
    }

}
